<template>

  <b-card 
    title="Inventario por caja"
    sub-title="Ingreso de seriales por caja">

    <b-row>
      <b-col cols="12">

        <inventory-by-box-card :stowage-id="stowageId"/>

      </b-col>
    </b-row>

  </b-card>
  
</template>

<script>
import router from '@/router'
import { BRow, BCol, BTabs, BTab, BCard } from 'bootstrap-vue'
import InventoryByBoxCard from './InventoryByBoxCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,

    InventoryByBoxCard,
  },
  data() {
    return {
      stowageId: null,
    }
  },
  created() {
    this.stowageId = router.currentRoute.params.stowage_id
    console.log(this.stowageId)
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>